import React from 'react';
import styled from 'styled-components';

import {Link, Trans} from 'gatsby-plugin-react-i18next';
import footer from '../../models/footer';

import FooterColumn from './Column';


const FooterNavigation = () => {
  return (
    footer.map(({title, items}) => (
      <FooterColumn key={title}>
        <H3><Trans>{title}</Trans></H3>
        {items.map(({title: linkTitle, path, url, external}) => (
          <FooterLink key={linkTitle}>
            {external ? (
              <a href={url} title={linkTitle} target="_blank" rel="noopener noreferrer nofollow">
                <Trans>{linkTitle}</Trans>
              </a>
            ) : (
              <Link to={path} title={linkTitle}>
                <Trans>{linkTitle}</Trans>
              </Link>
            )}
          </FooterLink>
        ))}
      </FooterColumn>
    ))
  );
};

const H3 = styled.h3`
  margin-top: 0;
  font-weight: 700;
  font-size: 1rem;
`;

const FooterLink = styled.div`
  margin-bottom: 1rem;

  a {
    color: white;
    font-weight: 300;
    font-size: 0.85rem;
  }
`;

export default FooterNavigation;
