const footer = [
  {
    title: 'Solutions',
    items: [
      {title: 'Intranets', path: '/solutions/intranets'},
      {title: 'Customer platforms', path: '/solutions/customer-platforms'},
      {title: 'Partner portals', path: '/solutions/partner-portals'},
      {title: 'Integration platforms', path: '/solutions/integration-platforms'},
    ],
  },
  {
    title: 'Products',
    items: [
        {
            title:'Newsletter campaigns',
            path: '/products#newsletter-campaigns'
        },
        {
            title: 'Storage limiter',
            path: '/products#starred-selector',
        },
        {
            title: 'FavDocs',
            path: '/products#starred-selector',
        },
        {
            title: 'Extended Webcontent Selector',
            path: '/products#extended-webcontent-selector',
        },
    ],
  },
  {
    title: 'Company',
    items: [
      {title: 'About Us', path: '/about-us'},
      {
        title: 'How we work',
        path: '/about-us#how-we-work',
      },
      {title: 'Contact', path: '/contact'},
      {title: 'LinkedIn', url: 'https://linkedin.com/company/coderylabs', external: true},
    ],
  },
];

export default footer;
