import throttle from 'lodash.throttle';

const checkWidth = (widthBenchmark) => {
  if (typeof window !== 'undefined') {
    const width = window.innerWidth;

    if (width > widthBenchmark) {
      return false;
    }

    return true;
  }

  return false;
};

const throttled = (widthBenchmark, callback) =>
  throttle(() => {
    callback(checkWidth(widthBenchmark));
  }, 1000);

const registerResizeHandler = (widthBenchmark, callback) => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', throttled(widthBenchmark, callback));
  }
};

const unregisterResizeHandler = (widthBenchmark, callback) => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('resize', throttled(widthBenchmark, callback));
  }
};

export {checkWidth, registerResizeHandler, unregisterResizeHandler};
