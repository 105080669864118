import React, {useEffect, useState, memo} from 'react';
import styled from 'styled-components';

import {checkWidth, registerResizeHandler, unregisterResizeHandler} from '../utils/windowWidth';

import GlobalStyles from './GlobalStyles';
import Header from './Header';

import CookiesPopup from '../components/CookiesPopup';

import Navigation from '../modules/Navigation';
import NavigationMobile from '../modules/NavigationMobile';
import Footer from '../modules/Footer';
import FooterMobile from '../modules/FooterMobile';

const PageWrapper = ({showNavigation = true, showFooter = true, style, urlPath, children, title, keywords, description}) => {
  const [isMobile, setMobileState] = useState(false);

  useEffect(() => {
    setMobileState(checkWidth(1040));
    registerResizeHandler(1040, setMobileState);

    return () => {
      unregisterResizeHandler(1040, setMobileState);
    };
  }, []);

  return (
    <GlobalStyles>
      <Header title={title} keywords={keywords} description={description} urlPath={urlPath}/>
      <Container style={style}>
        {showNavigation && !isMobile ? <Navigation/> : null}
        {showNavigation && isMobile ? <NavigationMobile/> : null}

        {children}

        {showFooter && !isMobile ? <Footer/> : null}
        {showFooter && isMobile ? <FooterMobile/> : null}
      </Container>

      <CookiesPopup/>
    </GlobalStyles>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export default memo(PageWrapper);
