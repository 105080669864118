import React from 'react';
import styled from 'styled-components';

const FooterCopyright = () => <Container>© 2O21 CoderyLabs Sp. z o.o. S.K. All Rights Reserved</Container>;

const Container = styled.div`
  font-size: 0.8rem;
  margin: 0;
  bottom: 0;
  left: 0;
`;

export default FooterCopyright;
