import React, {useState} from 'react';
import {Link, useI18next, Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

const SubNavigation = ({title, items}) => {
  const [subNavigationVisibility, toggleSubNavigationVisibility] = useState(false);

  return (
    <>
      <a
        href="/"
        onClick={(event) => {
          event.preventDefault();
          toggleSubNavigationVisibility(!subNavigationVisibility);
        }}
        onMouseEnter={(event) => {
          event.preventDefault();
          toggleSubNavigationVisibility(true);
        }}
        title={title}
      >
          <Trans>{title}</Trans>
      </a>
      <Container
        isOpen={subNavigationVisibility}
        onMouseLeave={(event) => {
          event.preventDefault();
          toggleSubNavigationVisibility(false);
        }}
      >
        <Content>
          {items.map(({title: subNavigationTitle, path: subNavigationPath}) => (
            <SubNavigationItem key={subNavigationPath}>
              <Link key={subNavigationTitle} to={subNavigationPath} title={subNavigationTitle}>
                  <Trans>
                      {subNavigationTitle}
                  </Trans>
              </Link>
            </SubNavigationItem>
          ))}
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 0 1rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  background: white;

  @media screen and (min-width: 1040px) {
    position: absolute;
    top: 80px;
    left: 0;
    margin: 0;
    height: 80px;
    display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  @media screen and (min-width: 1040px) {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    border-top: 2px solid black;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SubNavigationItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: bolder;

  @media screen and (min-width: 1040px) {
    display: block;
    padding: 0 2rem;
    font-size: 1.1rem;
  }
`;

export default SubNavigation;
