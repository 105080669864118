import React, {memo} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

const Button = ({to, title, href, children}) => (
  <Container>
    {href ? (
      <a href={href} title={title} target="_blank" rel="noopener noreferrer nofollow">
        {children}
      </a>
    ) : (
      <Link to={to} title={title}>
        {children}
      </Link>
    )}
  </Container>
);

const Container = styled.div`
  width: 200px;
  height: 50px;
  background: #39b54a;
  box-shadow: 3px 3px 6px #00000029;

  a {
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export default memo(Button);
