import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

import Img from 'gatsby-image';
import {Link, useI18next, Trans} from 'gatsby-plugin-react-i18next';
import SubNavigation from '../components/SubNavigation';
import Logo from '../components/Logo';
import Button from '../components/Button';

import navigation from '../models/navigation';

const Navigation = () => {
  const {changeLanguage} = useI18next();
  const {enIcon, plIcon} = useStaticQuery(graphql`
    query {
      enIcon: file(relativePath: { eq: "i18n/english.png" }) {
        childImageSharp {
          fixed(width: 20, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      plIcon: file(relativePath: { eq: "i18n/poland.png" }) {
        childImageSharp {
          fixed(width: 20, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Header>
      <NavigationContent>
        <Logo theme="dark"/>
        <NavigationContainer>
          {navigation.map(({title, path, items}) => (
            <NavigationItem key={title}>
                {path ? <Link to={path}>
                    <Trans>{title}</Trans>
                </Link> : ''}

              {items ? <SubNavigation title={title} items={items}/> : ''}
            </NavigationItem>
          ))}
        </NavigationContainer>

        <Button to="/contact" title="Request A Demo ">
            <Trans>Request A Demo</Trans> <FontAwesomeIcon icon={faChevronRight}/>
        </Button>

        <ul className="languages">
          <li>
            <button
              type="button"
              className="link-button"
              onClick={(e) => {
                e.preventDefault();
                changeLanguage('en');
              }}
            >
              <Img fixed={enIcon.childImageSharp.fixed} alt="English" style={{width: '20px'}}/>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="link-button"
              onClick={(e) => {
                e.preventDefault();
                changeLanguage('pl');
              }}
            >
              <Img fixed={plIcon.childImageSharp.fixed} alt="Polski" style={{width: '20px'}}/>
            </button>
          </li>
        </ul>
      </NavigationContent>
    </Header>
  );
};

const Header = styled.header`
  display: none;

  @media screen and (min-width: 1040px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    left: 0;

    width: 100%;
    height: 80px;
    background: white;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
    z-index: 999;
  }

  ul.languages {
    list-style-type: none;
    padding-top: 5px;
  }

  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  .link-button:hover,
  .link-button:focus {
    text-decoration: none;
  }
`;

const NavigationContent = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavigationContainer = styled.nav`
  display: flex;
`;

const NavigationItem = styled.div`
  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 1.5rem;

  a {
    color: black;
    font-weight: bolder;

    :hover {
      color: #39b54a;
    }
  }

  @media screen and (min-width: 1300px) {
    margin: 0 2.5rem;
  }
`;

export default Navigation;
