import React from 'react';
import styled from 'styled-components';

const FooterColumn = ({style, children}) => <Column style={style}>{children}</Column>;

const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default FooterColumn;
