import {memo} from 'react';
import {createPortal} from 'react-dom';

const Portal = ({isVisible, children}) => {
  const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

  if (portalRoot) {
    return createPortal(isVisible ? children : null, portalRoot);
  }

  return null;
};

export default memo(Portal);
