import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import FooterRow from './Row';
import FooterColumn from './Column';

const FooterContact = () => {
  const data = useStaticQuery(graphql`
    query {
      location: file(relativePath: { eq: "footer/location.png" }) {
        childImageSharp {
          fixed(width: 34) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      at: file(relativePath: { eq: "footer/at.png" }) {
        childImageSharp {
          fixed(width: 34) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      phone: file(relativePath: { eq: "footer/phone.png" }) {
        childImageSharp {
          fixed(width: 34) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <FooterColumn>
        <FooterRow>
          <Img fixed={data.location.childImageSharp.fixed} alt="Location icon"/>
          <FooterAddress>
            Kraków, Poland 30-554 <br/>
            Zamknięta 10 st.
          </FooterAddress>
        </FooterRow>
      </FooterColumn>
      <FooterColumn>
        <FooterRow>
          <Img fixed={data.at.childImageSharp.fixed} alt="At icon"/>
          <FooterAddress>contact@coderylabs.com</FooterAddress>
        </FooterRow>
      </FooterColumn>
      <FooterColumn>
        <FooterRow>
          <Img fixed={data.phone.childImageSharp.fixed} alt="Phone icon"/>
          <FooterAddress>
            +48 728-361-355 <br/>
            +48 668-594-571
          </FooterAddress>
        </FooterRow>
      </FooterColumn>
    </>
  );
};

const FooterAddress = styled.address`
  font-weight: 300;
  font-size: 1rem;
  font-style: normal;
  margin-left: 1rem;
  line-height: 1.3;
  display: flex;
  align-items: center;
`;

export default FooterContact;
