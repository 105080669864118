import React, {useState, useEffect} from 'react';
import {Link, useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faTimes, faChevronRight} from '@fortawesome/free-solid-svg-icons';

import navigation from '../models/navigation';
import SubNavigation from '../components/SubNavigation';
import Button from '../components/Button';

const NavigationMobile = () => {
  const [navigationVisibility, toggleNavigationVisibility] = useState(false);

  const {coderylabs} = useStaticQuery(graphql`
    query {
      coderylabs: file(relativePath: { eq: "logotypes/coderylabs_dark.png" }) {
        childImageSharp {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const scrollWatcher = () => {
      toggleNavigationVisibility(false);
    };

    if (navigationVisibility) {
      document.addEventListener('scroll', scrollWatcher);
    }

    return () => {
      document.removeEventListener('scroll', scrollWatcher);
    };
  }, [navigationVisibility]);

  return (
    <Container>
      <Header>
        <MenuHandler
          onClick={() => {
            toggleNavigationVisibility(!navigationVisibility);
          }}
        >
          <FontAwesomeIcon icon={navigationVisibility ? faTimes : faBars}/>
        </MenuHandler>

        <Link to="/" title="Main Page">
          <Img fixed={coderylabs.childImageSharp.fixed} alt="CoderyLabs logo"/>
        </Link>
      </Header>

      <MenuContainer isOpen={navigationVisibility}>
        {navigation.map(({title, path, items}) => (
          <NavigationItem key={title}>
            {path ? <Link to={path}>{title}</Link> : ''}

            {items ? <SubNavigation title={title} items={items}/> : ''}
          </NavigationItem>
        ))}

        <Button to="/contact" title="Request A Demo ">
          Request A Demo <FontAwesomeIcon icon={faChevronRight}/>
        </Button>
      </MenuContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;

  @media screen and (min-width: 1040px) {
    display: none;
  }
`;

const Header = styled.header`
  width: 100%;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 999;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);

  a {
    display: flex;
    align-items: center;
  }
`;

const MenuHandler = styled.div`
  width: 50px;
  height: 50px;
  color: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 1rem;
`;

const MenuContainer = styled.nav`
  display: flex;
  transition-duration: 500ms;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
  height: calc(100vh - 60px);
  top: 60px;
  background: white;
  box-sizing: border-box;
  position: absolute;
  left: ${({isOpen}) => (isOpen ? '0' : '-80%')};
  box-shadow: 11px 11px 11px 2px rgba(0, 0, 0, 0.17);
  padding: 1rem;
`;

const NavigationItem = styled.div`
  margin-bottom: 0.5rem;

  a {
    color: black;
    width: 100%;
    min-height: 40px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
`;

export default NavigationMobile;
