import React, {memo} from 'react';
import {Helmet} from 'react-helmet';

const defaultTitle = 'CoderyLabs';
const defaultDescription =
  'CoderyLabs provides highly specialized IT services for companies in different regions and industries. Whether you are looking for a web platform for your employees, customers, or partners, or you simply need a platform that unifies and integrates your multiple internal or external systems CoderyLabs has the right skillset, capabilities and experience to make it happen.';
const defaultKeywords = ['coderylabs', 'intranets', 'customer platforms', 'partner portals', 'partner platforms', 'integration platforms'];
const domain = 'https://coderylabs.com/';
const ogImageLink = `${domain}/og-image.png`;
const author = 'CoderyLabs';

const Header = ({title = '', description = '', keywords = [], urlPath = ''}) => (
  <Helmet>
    <html lang="en"/>
    <meta name="google-site-verification" content="0QCG8v-z_qu0Q0MumhPe3nD37qwQvBtnbA5EJHHLUjI"/>
    <meta httpEquiv="Cache-control" content="public"/>
    <meta name="author" content={author}/>

    <title>{title || defaultTitle}</title>

    <meta name="description" content={description || defaultDescription}/>
    <meta name="keywords" content={keywords.join(', ') || defaultKeywords.join(', ')}/>

    <meta property="og:title" content={title || defaultTitle}/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content={`${domain}/${urlPath}`}/>
    <meta property="og:image" content={ogImageLink}/>
    <meta property="og:site_name" content={defaultTitle}/>
    <meta property="og:description" content={description || defaultDescription}/>

    <meta property="twitter:card" content="summary"/>
    <meta property="twitter:title" content={title || defaultTitle}/>
    <meta property="twitter:description" content={description || defaultDescription}/>
    <meta property="twitter:image" content={ogImageLink}/>

    <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png"/>
    <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png"/>
    <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png"/>
    <link rel="apple-touch-icon" sizes="76x76" href="/icons/apple-icon-76x76.png"/>
    <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png"/>
    <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png"/>
    <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png"/>
    <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png"/>
    <link rel="shortcut icon" href="/favicon.ico"/>

    <link rel="manifest" href="/manifest.json"/>
    <meta name="msapplication-TileColor" content="#ffffff"/>
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
    <meta name="theme-color" content="#ffffff"/>

    <link rel="preconnect" href="https://www.gstatic.com"/>
    <link rel="preconnect" href="https://www.google-analytics.com"/>

    <link rel="dns-prefetch" href="https://www.gstatic.com"/>
    <link rel="dns-prefetch" href="https://www.google-analytics.com"/>

    <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@300;500;700&display=swap" rel="stylesheet"/>
  </Helmet>
);

export default memo(Header);
