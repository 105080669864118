import React, {memo} from 'react';
import {Link, useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Logo = ({theme}) => {
  const {coderylabsLight, liferayLight, coderylabsDark, liferayDark} = useStaticQuery(graphql`
    query {
      coderylabsLight: file(relativePath: { eq: "logotypes/coderylabs_light.png" }) {
        childImageSharp {
          fixed(width: 180, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      liferayLight: file(relativePath: { eq: "logotypes/liferay_light.png" }) {
        childImageSharp {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      coderylabsDark: file(relativePath: { eq: "logotypes/coderylabs_dark.png" }) {
        childImageSharp {
          fixed(width: 180, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      liferayDark: file(relativePath: { eq: "logotypes/liferay_dark.png" }) {
        childImageSharp {
          fixed(width: 150, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  const coderylabs = theme === 'dark' ? coderylabsDark : coderylabsLight;
  const liferay = theme === 'dark' ? liferayDark : liferayLight;

  return (
    <Container>
      <a href="https://www.liferay.com/" title="Liferay" rel="noopener noreferrer nofollow">
        <Img fixed={liferay.childImageSharp.fixed} alt="Liferay logo" style={{width: '100%'}}/>
      </a>
      <Link to="/" title="Main Page">
        <Img fixed={coderylabs.childImageSharp.fixed} alt="CoderyLabs logo" style={{width: '100%'}}/>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    height: 100%;
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain !important;
    }
  }
`;

export default memo(Logo);
