import React from 'react';
import styled from 'styled-components';

import FooterNavigation from '../components/Footer/Navigation';
import FooterContact from '../components/Footer/Contact';
import FooterCopyright from '../components/Footer/Copyright';

import FooterRow from '../components/Footer/Row';
import FooterColumn from '../components/Footer/Column';

import Logo from '../components/Logo';

const Footer = () => (
  <Container id="footer">
    <FooterRow  style={{marginBottom: '2rem'}}>
      <FooterColumn style={{alignSelf: 'center', marginRight: '2rem'}}>
        <Logo/>
      </FooterColumn>
      <FooterNavigation/>
    </FooterRow>

    <FooterRow style={{alignItems: 'center'}}>
      <FooterColumn style={{marginRight: '2rem'}}>
        <FooterCopyright/>
      </FooterColumn>

      <FooterContact/>
    </FooterRow>
  </Container>
);

const Container = styled.div`
  width: 100%;
  background: #222222;
  color: white;
  padding: 1.5rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default Footer;
