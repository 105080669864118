import React from 'react';
import styled from 'styled-components';

const FooterRow = ({children, style}) => <Row style={style}>{children}</Row>;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 680px) {
    flex-wrap: nowrap;
  }

  @media screen and (min-width: 1040px) {
    margin-bottom: 0;
  }
`;

export default FooterRow;
