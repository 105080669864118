import React, {memo, useState, useEffect} from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

import Portal from '../containers/Portal';

const CookiesPopup = () => {
  const [isVisible, setVisibility] = useState(false);
  const cookies = new Cookies();
  const isCookieSet = cookies.get('cookieInformation') === 'true';

  useEffect(() => {
    if (!isCookieSet) {
      setVisibility(true);
    }
  }, []);

  const onClose = () => {
    cookies.set('cookieInformation', 'true');
    setVisibility(false);
  };

  return (
    <Portal isVisible={isVisible}>
      <Wrapper>
        <Content>
          By using this website, you automatically accept that we use cookies. Read more information in
          <a href="/privacy-policy" title="Privacy Policy">
            Privacy Policy.
          </a>
        </Content>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes}/>
        </CloseButton>
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  background: white;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 999;
  display: flex;
  align-items: center;
  border-top: 2px solid #39b54a;
`;

const Content = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: #39b54a;

  a {
    margin-left: 0.3rem;
    color: #39b54a;
    font-weight: bold;
  }
`;

const CloseButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #39b54a;
  font-size: 1.5rem;
  cursor: pointer;
`;

export default memo(CookiesPopup);
